import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { IconUser, Select } from 'hds-react';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/dropdown/customisation",
  "title": "Dropdown - Customisation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component. See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-background-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-background-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of disabled dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-border-color-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Border color of dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-border-color-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Border color of hovered dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-border-color-hover-invalid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Border color of hovered and invalid dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-border-color-focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Border color of focused dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-border-color-invalid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Border color of invalid dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-border-color-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Border color of disabled dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-color-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dropdown color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--dropdown-color-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disabled dropdown color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--focus-outline-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Focus outline color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--helper-color-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper text color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--helper-color-invalid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper text color when dropdown state is invalid`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-divider-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menu divider color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-background-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-background-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of hovered menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-background-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of selected menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-background-selected-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of selected and hovered menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-background-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of disabled menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-color-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-color-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of hovered menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-color-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of selected menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-color-selected-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of selected and hovered menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-color-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of disabled menu item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-icon-color-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of selected menu item icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--menu-item-icon-color-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of disabled menu item icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--multiselect-checkbox-background-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of selected multiselect checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--multiselect-checkbox-background-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of disabled multiselect checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--multiselect-checkbox-border-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of multiselect checkbox border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--multiselect-checkbox-border-hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of hovered multiselect checkbox border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--multiselect-checkbox-border-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of disabled multiselect checkbox border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--multiselect-checkbox-color-default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of multiselect checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--multiselect-checkbox-color-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of selected multiselect checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--multiselect-checkbox-color-selected-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color of selected and disabled multiselect checkbox`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--placeholder-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder color`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const options = [{ label: 'Plutonium' }, { label: 'Americium' }, { label: 'Copernicium' }, { label: 'Berkelium' }];
  return (
    <>
      <Select
        required
        label="Label"
        helper="Assistive text"
        placeholder="Placeholder"
        icon={<IconUser />}
        options={options}
        style={{ marginTop: 'var(--spacing-xs)' }}
        theme={{
          '--dropdown-background-default': 'var(--color-black-5)',
          '--dropdown-background-disabled': 'var(--color-black-10)',
          '--dropdown-border-color-default': 'var(--color-black-50)',
          '--dropdown-border-color-hover': 'var(--color-black-90)',
          '--dropdown-border-color-hover-invalid': 'var(--color-error-dark)',
          '--dropdown-border-color-focus': 'var(--color-black-90)',
          '--dropdown-border-color-invalid': 'var(--color-error)',
          '--dropdown-border-color-disabled': 'var(--color-black-10)',
          '--dropdown-color-default': 'var(--color-black-90)',
          '--dropdown-color-disabled': 'var(--color-black-40)',
          '--focus-outline-color': 'var(--color-coat-of-arms)',
          '--helper-color-default': 'var(--color-black-60)',
          '--helper-color-invalid': 'var(--color-error)',
          '--menu-divider-color': 'var(--color-black-20)',
          '--menu-item-background-default': 'var(--color-white)',
          '--menu-item-background-hover': 'var(--color-bus)',
          '--menu-item-background-selected': 'var(--color-white)',
          '--menu-item-background-selected-hover': 'var(--color-bus)',
          '--menu-item-background-disabled': 'var(--color-white)',
          '--menu-item-color-default': 'var(--color-black-90)',
          '--menu-item-color-hover': 'var(--color-white)',
          '--menu-item-color-selected': 'var(--color-black-90)',
          '--menu-item-color-selected-hover': 'var(--color-white)',
          '--menu-item-color-disabled': 'var(--color-black-40)',
          '--menu-item-icon-color-selected': 'var(--color-white)',
          '--menu-item-icon-color-disabled': 'var(--color-black-40)',
          '--multiselect-checkbox-background-selected': 'var(--color-bus)',
          '--multiselect-checkbox-background-disabled': 'var(--color-black-10)',
          '--multiselect-checkbox-border-default': 'var(--color-black-50)',
          '--multiselect-checkbox-border-hover': 'var(--color-black-90)',
          '--multiselect-checkbox-border-disabled': 'var(--color-black-10)',
          '--multiselect-checkbox-color-default': 'transparent',
          '--multiselect-checkbox-color-selected': 'var(--color-white)',
          '--multiselect-checkbox-color-selected-disabled': 'var(--color-white)',
          '--placeholder-color': 'var(--color-black-60)',
        }}
      />
    </>
  )
}}
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      